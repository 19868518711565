import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const provinces = [
  "Metro Manila",
  "Cebu",
  "Davao del Sur", // Add other provinces here
  // Complete list of provinces
  "Abra",
  "Agusan del Norte",
  "Agusan del Sur",
  "Aklan",
  "Albay",
  "Antique",
  "Apayao",
  "Aurora",
  "Basilan",
  "Bataan",
  "Batanes",
  "Batangas",
  "Benguet",
  "Biliran",
  "Bohol",
  "Bukidnon",
  "Bulacan",
  "Cagayan",
  "Camarines Norte",
  "Camarines Sur",
  "Camiguin",
  "Capiz",
  "Catanduanes",
  "Cavite",
  "Cotabato",
  "Davao Occidental",
  "Davao Oriental",
  "Dinagat Islands",
  "Eastern Samar",
  "Guimaras",
  "Ifugao",
  "Ilocos Norte",
  "Ilocos Sur",
  "Iloilo",
  "Isabela",
  "Kalinga",
  "La Union",
  "Laguna",
  "Lanao del Norte",
  "Lanao del Sur",
  "Leyte",
  "Maguindanao",
  "Marinduque",
  "Masbate",
  "Misamis Occidental",
  "Misamis Oriental",
  "Mountain Province",
  "Negros Occidental",
  "Negros Oriental",
  "Northern Samar",
  "Nueva Ecija",
  "Nueva Vizcaya",
  "Occidental Mindoro",
  "Oriental Mindoro",
  "Palawan",
  "Pampanga",
  "Pangasinan",
  "Quezon",
  "Quirino",
  "Rizal",
  "Romblon",
  "Samar",
  "Sarangani",
  "Siquijor",
  "Sorsogon",
  "South Cotabato",
  "Southern Leyte",
  "Sultan Kudarat",
  "Sulu",
  "Surigao del Norte",
  "Surigao del Sur",
  "Tarlac",
  "Tawi-Tawi",
  "Zambales",
  "Zamboanga del Norte",
  "Zamboanga del Sur",
  "Zamboanga Sibugay",
];

const cities = {
  "Metro Manila": [
    "Caloocan",
    "Las Piñas",
    "Makati",
    "Malabon",
    "Mandaluyong",
    "Manila",
    "Marikina",
    "Muntinlupa",
    "Navotas",
    "Parañaque",
    "Pasay",
    "Pasig",
    "Quezon City",
    "San Juan",
    "Taguig",
    "Valenzuela",
  ],
  Cebu: [
    "Bogo",
    "Carcar",
    "Cebu City",
    "Danao",
    "Lapu-Lapu",
    "Mandaue",
    "Naga",
    "Talisay",
    "Toledo",
  ],
  "Davao del Sur": [
    "Davao City",
    "Digos",
    "Bansalan",
    "Hagonoy",
    "Kiblawan",
    "Magsaysay",
    "Malalag",
    "Matanao",
    "Padada",
    "Santa Cruz",
  ],
  // Add other cities/municipalities for each province
  Abra: [
    "Bangued",
    "Boliney",
    "Bucay",
    "Bucloc",
    "Daguioman",
    "Danglas",
    "Dolores",
    "La Paz",
    "Lacub",
    "Lagangilang",
    "Lagayan",
    "Langiden",
    "Licuan-Baay",
    "Luba",
    "Malibcong",
    "Manabo",
    "Peñarrubia",
    "Pidigan",
    "Pilar",
    "Sallapadan",
    "San Isidro",
    "San Juan",
    "San Quintin",
    "Tayum",
    "Tineg",
    "Tubo",
    "Villaviciosa",
  ],
  "Agusan del Norte": [
    "Butuan",
    "Cabadbaran",
    "Bayugan",
    "Buenavista",
    "Carmen",
    "Jabonga",
    "Kitcharao",
    "Las Nieves",
    "Magallanes",
    "Nasipit",
    "Remedios T. Romualdez",
    "Santiago",
    "Tubay",
  ],
  "Agusan del Sur": [
    "Bayugan",
    "Bunawan",
    "Esperanza",
    "La Paz",
    "Loreto",
    "Prosperidad",
    "Rosario",
    "San Francisco",
    "San Luis",
    "Santa Josefa",
    "Sibagat",
    "Talacogon",
    "Trento",
    "Veruela",
  ],
  Aklan: [
    "Altavas",
    "Balete",
    "Banga",
    "Batan",
    "Buruanga",
    "Ibajay",
    "Kalibo",
    "Lezo",
    "Libacao",
    "Madalag",
    "Makato",
    "Malay",
    "Malinao",
    "Nabas",
    "New Washington",
    "Numancia",
    "Tangalan",
  ],
  Albay: [
    "Legazpi",
    "Ligao",
    "Tabaco",
    "Bacacay",
    "Camalig",
    "Daraga",
    "Guinobatan",
    "Jovellar",
    "Libon",
    "Malilipot",
    "Malinao",
    "Manito",
    "Oas",
    "Pio Duran",
    "Polangui",
    "Rapu-Rapu",
    "Santo Domingo",
    "Tiwi",
  ],
  Antique: [
    "Anini-y",
    "Barbaza",
    "Belison",
    "Bugasong",
    "Caluya",
    "Culasi",
    "Hamtic",
    "Laua-an",
    "Libertad",
    "Pandan",
    "Patnongon",
    "San Jose",
    "San Remigio",
    "Sebaste",
    "Sibalom",
    "Tibiao",
    "Tobias Fornier",
    "Valderrama",
  ],
  Apayao: [
    "Calanasan",
    "Conner",
    "Flora",
    "Kabugao",
    "Luna",
    "Pudtol",
    "Santa Marcela",
  ],
  Aurora: [
    "Baler",
    "Casiguran",
    "Dilasag",
    "Dinalungan",
    "Dingalan",
    "Dipaculao",
    "Maria Aurora",
    "San Luis",
  ],
  Basilan: [
    "Isabela",
    "Lamitan",
    "Akbar",
    "Al-Barka",
    "Hadji Mohammad Ajul",
    "Hadji Muhtamad",
    "Lantawan",
    "Maluso",
    "Sumisip",
    "Tabuan-Lasa",
    "Tipo-Tipo",
    "Tuburan",
    "Ungkaya Pukan",
  ],
  Bataan: [
    "Balanga",
    "Abucay",
    "Bagac",
    "Dinalupihan",
    "Hermosa",
    "Limay",
    "Mariveles",
    "Morong",
    "Orani",
    "Orion",
    "Pilar",
    "Samal",
  ],
  Batanes: ["Basco", "Itbayat", "Ivana", "Mahatao", "Sabtang", "Uyugan"],
  Batangas: [
    "Batangas City",
    "Lipa",
    "Tanauan",
    "Agoncillo",
    "Alitagtag",
    "Balayan",
    "Balete",
    "Bauan",
    "Calaca",
    "Calatagan",
    "Cuenca",
    "Ibaan",
    "Laurel",
    "Lemery",
    "Lian",
    "Lobo",
    "Mabini",
    "Malvar",
    "Mataasnakahoy",
    "Nasugbu",
    "Padre Garcia",
    "Rosario",
    "San Jose",
    "San Juan",
    "San Luis",
    "San Nicolas",
    "San Pascual",
    "Santa Teresita",
    "Santo Tomas",
    "Taal",
    "Talisay",
    "Taysan",
    "Tingloy",
    "Tuy",
  ],
  Benguet: [
    "Baguio",
    "Atok",
    "Bakun",
    "Bokod",
    "Buguias",
    "Itogon",
    "Kabayan",
    "Kapangan",
    "Kibungan",
    "La Trinidad",
    "Mankayan",
    "Sablan",
    "Tuba",
    "Tublay",
  ],
  Biliran: [
    "Almeria",
    "Biliran",
    "Cabucgayan",
    "Caibiran",
    "Culaba",
    "Kawayan",
    "Maripipi",
    "Naval",
  ],
  Bohol: [
    "Tagbilaran",
    "Alburquerque",
    "Alicia",
    "Anda",
    "Antequera",
    "Baclayon",
    "Balilihan",
    "Batuan",
    "Bien Unido",
    "Bilar",
    "Buenavista",
    "Calape",
    "Candijay",
    "Carmen",
    "Catigbian",
    "Clarin",
    "Corella",
    "Cortes",
    "Dagohoy",
    "Danao",
    "Dauis",
    "Dimiao",
    "Duero",
    "Garcia Hernandez",
    "Getafe",
    "Guindulman",
    "Inabanga",
    "Jagna",
    "Lila",
    "Loay",
    "Loboc",
    "Loon",
    "Mabini",
    "Maribojoc",
    "Panglao",
    "Pilar",
    "Pres. Carlos P. Garcia",
    "Sagbayan",
    "San Isidro",
    "San Miguel",
    "Sevilla",
    "Sierra Bullones",
    "Sikatuna",
    "Talibon",
    "Trinidad",
    "Tubigon",
    "Ubay",
    "Valencia",
  ],
  Bukidnon: [
    "Malaybalay",
    "Valencia",
    "Baungon",
    "Cabanglasan",
    "Damulog",
    "Dangcagan",
    "Don Carlos",
    "Impasug-ong",
    "Kadingilan",
    "Kalilangan",
    "Kibawe",
    "Kitaotao",
    "Lantapan",
    "Libona",
    "Malitbog",
    "Manolo Fortich",
    "Maramag",
    "Pangantucan",
    "Quezon",
    "San Fernando",
    "Sumilao",
    "Talakag",
  ],
  Bulacan: [
    "Malolos",
    "Meycauayan",
    "San Jose del Monte",
    "Angat",
    "Balagtas",
    "Baliuag",
    "Bocaue",
    "Bulakan",
    "Bustos",
    "Calumpit",
    "Doña Remedios Trinidad",
    "Guiguinto",
    "Hagonoy",
    "Marilao",
    "Norzagaray",
    "Obando",
    "Pandi",
    "Paombong",
    "Plaridel",
    "Pulilan",
    "San Ildefonso",
    "San Miguel",
    "San Rafael",
    "Santa Maria",
  ],
  Cagayan: [
    "Tuguegarao",
    "Abulug",
    "Alcala",
    "Allacapan",
    "Amulung",
    "Aparri",
    "Baggao",
    "Ballesteros",
    "Buguey",
    "Calayan",
    "Camalaniugan",
    "Claveria",
    "Enrile",
    "Gattaran",
    "Gonzaga",
    "Iguig",
    "Lal-lo",
    "Lasam",
    "Pamplona",
    "Peñablanca",
    "Piat",
    "Rizal",
    "Sanchez-Mira",
    "Santa Ana",
    "Santa Praxedes",
    "Santa Teresita",
    "Santo Niño",
    "Solana",
    "Tuao",
  ],
  "Camarines Norte": [
    "Daet",
    "Basud",
    "Capalonga",
    "Jose Panganiban",
    "Labo",
    "Mercedes",
    "Paracale",
    "San Lorenzo Ruiz",
    "San Vicente",
    "Santa Elena",
    "Talisay",
    "Vinzons",
  ],
  "Camarines Sur": [
    "Naga",
    "Iriga",
    "Angeles City",
    "Basud",
    "Baao",
    "Balatan",
    "Bato",
    "Bombon",
    "Buhi",
    "Bula",
    "Cabusao",
    "Calabanga",
    "Camaligan",
    "Canaman",
    "Caramoan",
    "Del Gallego",
    "Gainza",
    "Garchitorena",
    "Goa",
    "Lagonoy",
    "Libmanan",
    "Lupi",
    "Magarao",
    "Milaor",
    "Minalabac",
    "Nabua",
    "Ocampo",
    "Pamplona",
    "Pasacao",
    "Pili",
    "Presentacion",
    "Ragay",
    "Sagnay",
    "San Fernando",
    "San Jose",
    "Sipocot",
    "Siruma",
    "Tigaon",
    "Tinambac",
  ],
  Camiguin: ["Catarman", "Guinsiliban", "Mahinog", "Mambajao", "Sagay"],
  Capiz: [
    "Roxas",
    "Cuartero",
    "Dao",
    "Dumalag",
    "Dumarao",
    "Ivisan",
    "Jamindan",
    "Ma-ayon",
    "Mambusao",
    "Panay",
    "Panitan",
    "Pilar",
    "Pontevedra",
    "President Roxas",
    "Sapian",
    "Sigma",
    "Tapaz",
  ],
  Catanduanes: [
    "Virac",
    "Bagamanoc",
    "Baras",
    "Bato",
    "Caramoran",
    "Gigmoto",
    "Pandan",
    "Panganiban",
    "San Andres",
    "San Miguel",
    "Viga",
  ],
  Cavite: [
    "Bacoor",
    "Cavite City",
    "Dasmariñas",
    "General Trias",
    "Imus",
    "Tagaytay",
    "Trece Martires",
    "Alfonso",
    "Amadeo",
    "Carmona",
    "General Mariano Alvarez",
    "Indang",
    "Kawit",
    "Magallanes",
    "Maragondon",
    "Mendez",
    "Naic",
    "Noveleta",
    "Rosario",
    "Silang",
    "Tanza",
    "Ternate",
  ],
  Cotabato: [
    "Kidapawan",
    "Alamada",
    "Aleosan",
    "Antipas",
    "Arakan",
    "Banisilan",
    "Carmen",
    "Kabacan",
    "Libungan",
    "Magpet",
    "M'lang",
    "Makilala",
    "Matalam",
    "Midsayap",
    "Pigcawayan",
    "Pikit",
    "President Roxas",
    "Tulunan",
  ],
  "Davao Occidental": [
    "Don Marcelino",
    "Jose Abad Santos",
    "Malita",
    "Santa Maria",
    "Sarangani",
  ],
  "Davao Oriental": [
    "Mati",
    "Baganga",
    "Banaybanay",
    "Boston",
    "Caraga",
    "Cateel",
    "Governor Generoso",
    "Lupon",
    "Manay",
    "San Isidro",
    "Tarragona",
  ],
  "Dinagat Islands": [
    "Basilisa",
    "Cagdianao",
    "Dinagat",
    "Libjo",
    "Loreto",
    "San Jose",
    "Tubajon",
  ],
  "Eastern Samar": [
    "Borongan",
    "Arteche",
    "Balangiga",
    "Balangkayan",
    "Can-avid",
    "Dolores",
    "General MacArthur",
    "Giporlos",
    "Guiuan",
    "Hernani",
    "Jipapad",
    "Lawaan",
    "Llorente",
    "Maslog",
    "Maydolong",
    "Mercedes",
    "Oras",
    "Quinapondan",
    "Salcedo",
    "San Julian",
    "San Policarpo",
    "Sulat",
    "Taft",
  ],
  Guimaras: [
    "Buenavista",
    "Jordan",
    "Nueva Valencia",
    "San Lorenzo",
    "Sibunag",
  ],
  Ifugao: [
    "Aguinaldo",
    "Alfonso Lista",
    "Asipulo",
    "Banaue",
    "Hingyon",
    "Hungduan",
    "Kiangan",
    "Lagawe",
    "Lamut",
    "Mayoyao",
    "Tinoc",
  ],
  "Ilocos Norte": [
    "Laoag",
    "Adams",
    "Bacarra",
    "Badoc",
    "Bangui",
    "Banna",
    "Batac",
    "Burgos",
    "Carasi",
    "Currimao",
    "Dingras",
    "Dumalneg",
    "Marcos",
    "Nueva Era",
    "Pagudpud",
    "Paoay",
    "Pasuquin",
    "Piddig",
    "Pinili",
    "San Nicolas",
    "Sarrat",
    "Solsona",
    "Vintar",
  ],
  "Ilocos Sur": [
    "Vigan",
    "Alilem",
    "Banayoyo",
    "Bantay",
    "Burgos",
    "Cabugao",
    "Candon",
    "Caoayan",
    "Cervantes",
    "Galimuyod",
    "Gregorio del Pilar",
    "Lidlidda",
    "Magsingal",
    "Nagbukel",
    "Narvacan",
    "Quirino",
    "Salcedo",
    "San Emilio",
    "San Esteban",
    "San Ildefonso",
    "San Juan",
    "San Vicente",
    "Santa",
    "Santa Catalina",
    "Santa Cruz",
    "Santa Lucia",
    "Santa Maria",
    "Santiago",
    "Santo Domingo",
    "Sigay",
    "Sinait",
    "Sugpon",
    "Suyo",
    "Tagudin",
  ],
  Iloilo: [
    "Iloilo City",
    "Passi",
    "Ajuy",
    "Alimodian",
    "Anilao",
    "Badiangan",
    "Balasan",
    "Banate",
    "Barotac Nuevo",
    "Barotac Viejo",
    "Batad",
    "Bingawan",
    "Cabatuan",
    "Calinog",
    "Carles",
    "Concepcion",
    "Dingle",
    "Dueñas",
    "Dumangas",
    "Estancia",
    "Guimbal",
    "Igbaras",
    "Janiuay",
    "Lambunao",
    "Leganes",
    "Lemery",
    "Leon",
    "Maasin",
    "Miagao",
    "Mina",
    "New Lucena",
    "Oton",
    "Pavia",
    "Pototan",
    "San Dionisio",
    "San Enrique",
    "San Joaquin",
    "San Miguel",
    "San Rafael",
    "Santa Barbara",
    "Sara",
    "Tigbauan",
    "Tubungan",
    "Zarraga",
  ],
  Isabela: [
    "Ilagan",
    "Cauayan",
    "Santiago",
    "Alicia",
    "Angadanan",
    "Aurora",
    "Benito Soliven",
    "Burgos",
    "Cabagan",
    "Cabatuan",
    "Cordon",
    "Delfin Albano",
    "Dinapigue",
    "Divilacan",
    "Echague",
    "Gamu",
    "Ilagan",
    "Jones",
    "Luna",
    "Maconacon",
    "Mallig",
    "Naguilian",
    "Palanan",
    "Quezon",
    "Quirino",
    "Ramon",
    "Reina Mercedes",
    "Roxas",
    "San Agustin",
    "San Guillermo",
    "San Isidro",
    "San Manuel",
    "San Mariano",
    "San Mateo",
    "San Pablo",
    "Santa Maria",
    "Santo Tomas",
    "Tumauini",
  ],
  Kalinga: [
    "Tabuk",
    "Balbalan",
    "Lubuagan",
    "Pasil",
    "Pinukpuk",
    "Rizal",
    "Tanudan",
    "Tinglayan",
  ],
  "La Union": [
    "San Fernando",
    "Agoo",
    "Aringay",
    "Bacnotan",
    "Bagulin",
    "Balaoan",
    "Bangar",
    "Bauang",
    "Burgos",
    "Caba",
    "Luna",
    "Naguilian",
    "Pugo",
    "Rosario",
    "San Gabriel",
    "San Juan",
    "Santo Tomas",
    "Santol",
    "Sudipen",
    "Tubao",
  ],
  Laguna: [
    "San Pedro",
    "Santa Rosa",
    "Biñan",
    "Cabuyao",
    "Calamba",
    "Santa Cruz",
    "Alaminos",
    "Bay",
    "Calauan",
    "Cavinti",
    "Famy",
    "Kalayaan",
    "Liliw",
    "Los Baños",
    "Luisiana",
    "Lumban",
    "Mabitac",
    "Magdalena",
    "Majayjay",
    "Nagcarlan",
    "Paete",
    "Pagsanjan",
    "Pakil",
    "Pangil",
    "Pila",
    "Rizal",
    "San Pablo",
    "San Pedro",
    "Santa Cruz",
    "Santa Maria",
    "Siniloan",
    "Victoria",
  ],
  "Lanao del Norte": [
    "Iligan",
    "Bacolod",
    "Baloi",
    "Baroy",
    "Kapatagan",
    "Kauswagan",
    "Kolambugan",
    "Lala",
    "Linamon",
    "Magsaysay",
    "Maigo",
    "Matungao",
    "Munai",
    "Nunungan",
    "Pantao Ragat",
    "Pantar",
    "Poona Piagapo",
    "Salvador",
    "Sapad",
    "Sultan Naga Dimaporo",
    "Tagoloan",
    "Tangcal",
    "Tubod",
  ],
  "Lanao del Sur": [
    "Marawi",
    "Bacolod-Kalawi",
    "Balabagan",
    "Balindong",
    "Bayang",
    "Binidayan",
    "Buadiposo-Buntong",
    "Bubong",
    "Bumbaran",
    "Butig",
    "Calanogas",
    "Ditsaan-Ramain",
    "Ganassi",
    "Kapai",
    "Kapatagan",
    "Lumba-Bayabao",
    "Lumbaca-Unayan",
    "Lumbatan",
    "Lumbayanague",
    "Madalum",
    "Madamba",
    "Maguing",
    "Malabang",
    "Marantao",
    "Marogong",
    "Masiu",
    "Mulondo",
    "Pagayawan",
    "Piagapo",
    "Picong",
    "Poona Bayabao",
    "Pualas",
    "Saguiaran",
    "Sultan Dumalondong",
    "Tagoloan II",
    "Tamparan",
    "Taraka",
    "Tubaran",
    "Tugaya",
    "Wao",
  ],
  Leyte: [
    "Tacloban",
    "Abuyog",
    "Alangalang",
    "Albuera",
    "Babatngon",
    "Barugo",
    "Bato",
    "Baybay",
    "Burauen",
    "Calubian",
    "Capoocan",
    "Carigara",
    "Dagami",
    "Dulag",
    "Hilongos",
    "Hindang",
    "Inopacan",
    "Isabel",
    "Jaro",
    "Javier",
    "Julita",
    "Kananga",
    "La Paz",
    "Leyte",
    "MacArthur",
    "Mahaplag",
    "Matag-ob",
    "Matalom",
    "Mayorga",
    "Merida",
    "Ormoc",
    "Palo",
    "Palompon",
    "Pastrana",
    "San Isidro",
    "San Miguel",
    "Santa Fe",
    "Tabango",
    "Tabontabon",
    "Tanauan",
    "Tolosa",
    "Tunga",
    "Villaba",
  ],
  Maguindanao: [
    "Buluan",
    "Ampatuan",
    "Barira",
    "Buldon",
    "Datu Abdullah Sangki",
    "Datu Anggal Midtimbang",
    "Datu Blah T. Sinsuat",
    "Datu Hoffer Ampatuan",
    "Datu Montawal",
    "Datu Odin Sinsuat",
    "Datu Paglas",
    "Datu Piang",
    "Datu Salibo",
    "Datu Saudi-Ampatuan",
    "Datu Unsay",
    "Gen. S. K. Pendatun",
    "Guindulungan",
    "Kabuntalan",
    "Mamasapano",
    "Mangudadatu",
    "Matanog",
    "Northern Kabuntalan",
    "Pagalungan",
    "Paglat",
    "Pandag",
    "Parang",
    "Rajah Buayan",
    "Shariff Aguak",
    "Shariff Saydona Mustapha",
    "South Upi",
    "Sultan Kudarat",
    "Sultan Mastura",
    "Sultan sa Barongis",
    "Talayan",
    "Talitay",
    "Upi",
  ],
  Marinduque: [
    "Boac",
    "Buenavista",
    "Gasan",
    "Mogpog",
    "Santa Cruz",
    "Torrijos",
  ],
  Masbate: [
    "Masbate City",
    "Aroroy",
    "Baleno",
    "Balud",
    "Batuan",
    "Cataingan",
    "Cawayan",
    "Claveria",
    "Dimasalang",
    "Esperanza",
    "Mandaon",
    "Milagros",
    "Mobo",
    "Monreal",
    "Palanas",
    "Pio V. Corpuz",
    "Placer",
    "San Fernando",
    "San Jacinto",
    "San Pascual",
    "Uson",
  ],
  "Misamis Occidental": [
    "Oroquieta",
    "Ozamiz",
    "Tangub",
    "Aloran",
    "Baliangao",
    "Bonifacio",
    "Calamba",
    "Clarin",
    "Concepcion",
    "Don Victoriano Chiongbian",
    "Jimenez",
    "Lopez Jaena",
    "Panaon",
    "Plaridel",
    "Sapang Dalaga",
    "Sinacaban",
    "Tudela",
  ],
  "Misamis Oriental": [
    "Cagayan de Oro",
    "El Salvador",
    "Gingoog",
    "Alubijid",
    "Balingasag",
    "Balingoan",
    "Binuangan",
    "Claveria",
    "Gitagum",
    "Initao",
    "Jasaan",
    "Kinoguitan",
    "Lagonglong",
    "Laguindingan",
    "Libertad",
    "Lugait",
    "Magsaysay",
    "Manticao",
    "Medina",
    "Naawan",
    "Opol",
    "Salay",
    "Sugbongcogon",
    "Tagoloan",
    "Talisayan",
    "Villanueva",
  ],
  "Mountain Province": [
    "Barlig",
    "Bauko",
    "Besao",
    "Bontoc",
    "Natonin",
    "Paracelis",
    "Sabangan",
    "Sadanga",
    "Sagada",
    "Tadian",
  ],
  "Negros Occidental": [
    "Bacolod",
    "Bago",
    "Cadiz",
    "Escalante",
    "Himamaylan",
    "Kabankalan",
    "La Carlota",
    "Sagay",
    "San Carlos",
    "Silay",
    "Sipalay",
    "Talisay",
    "Victorias",
    "Binalbagan",
    "Calatrava",
    "Candoni",
    "Cauayan",
    "Enrique B. Magalona",
    "Hinigaran",
    "Hinoba-an",
    "Ilog",
    "Isabela",
    "La Castellana",
    "Manapla",
    "Moises Padilla",
    "Murcia",
    "Pontevedra",
    "Pulupandan",
    "Salvador Benedicto",
    "San Enrique",
    "Toboso",
    "Valladolid",
  ],
  "Negros Oriental": [
    "Dumaguete",
    "Bais",
    "Bayawan",
    "Canlaon",
    "Guihulngan",
    "Tanjay",
    "Amlan",
    "Ayungon",
    "Bacong",
    "Basay",
    "Bindoy",
    "Dauin",
    "Jimalalud",
    "La Libertad",
    "Mabinay",
    "Manjuyod",
    "Pamplona",
    "San Jose",
    "Santa Catalina",
    "Siaton",
    "Sibulan",
    "Tayasan",
    "Valencia",
    "Vallehermoso",
    "Zamboanguita",
  ],
  "Northern Samar": [
    "Catarman",
    "Allen",
    "Biri",
    "Bobon",
    "Capul",
    "Catubig",
    "Gamay",
    "Laoang",
    "Lapinig",
    "Las Navas",
    "Lavezares",
    "Lope de Vega",
    "Mapanas",
    "Mondragon",
    "Palapag",
    "Pambujan",
    "Rosario",
    "San Antonio",
    "San Isidro",
    "San Jose",
    "San Roque",
    "San Vicente",
    "Silvino Lobos",
    "Victoria",
  ],
  "Nueva Ecija": [
    "Cabanatuan",
    "Gapan",
    "Muñoz",
    "Palayan",
    "San Jose",
    "Aliaga",
    "Bongabon",
    "Cabiao",
    "Carranglan",
    "Cuyapo",
    "Gabaldon",
    "General Mamerto Natividad",
    "General Tinio",
    "Guimba",
    "Jaen",
    "Laur",
    "Licab",
    "Llanera",
    "Lupao",
    "Nampicuan",
    "Pantabangan",
    "Peñaranda",
    "Quezon",
    "Rizal",
    "San Antonio",
    "San Isidro",
    "San Leonardo",
    "Santa Rosa",
    "Santo Domingo",
    "Talavera",
    "Talugtug",
    "Zaragoza",
  ],
  "Nueva Vizcaya": [
    "Bayombong",
    "Alfonso Castañeda",
    "Ambaguio",
    "Aritao",
    "Bagabag",
    "Bambang",
    "Diadi",
    "Dupax del Norte",
    "Dupax del Sur",
    "Kasibu",
    "Kayapa",
    "Quezon",
    "Santa Fe",
    "Solano",
    "Villaverde",
  ],
  "Occidental Mindoro": [
    "Abra de Ilog",
    "Calintaan",
    "Looc",
    "Lubang",
    "Magsaysay",
    "Mamburao",
    "Paluan",
    "Rizal",
    "Sablayan",
    "San Jose",
    "Santa Cruz",
  ],
  "Oriental Mindoro": [
    "Calapan",
    "Baco",
    "Bansud",
    "Bongabong",
    "Bulalacao",
    "Gloria",
    "Mansalay",
    "Naujan",
    "Pinamalayan",
    "Pola",
    "Puerto Galera",
    "Roxas",
    "San Teodoro",
    "Socorro",
    "Victoria",
  ],
  Palawan: [
    "Puerto Princesa",
    "Aborlan",
    "Agutaya",
    "Araceli",
    "Balabac",
    "Bataraza",
    "Brooke's Point",
    "Busuanga",
    "Cagayancillo",
    "Coron",
    "Culion",
    "Cuyo",
    "Dumaran",
    "El Nido",
    "Kalayaan",
    "Linapacan",
    "Magsaysay",
    "Narra",
    "Quezon",
    "Rizal",
    "Roxas",
    "San Vicente",
    "Sofronio Española",
    "Taytay",
  ],
  Pampanga: [
    "Angeles",
    "Mabalacat",
    "San Fernando",
    "Apalit",
    "Arayat",
    "Bacolor",
    "Candaba",
    "Floridablanca",
    "Guagua",
    "Lubao",
    "Macabebe",
    "Magalang",
    "Masantol",
    "Mexico",
    "Minalin",
    "Porac",
    "San Luis",
    "San Simon",
    "Santa Ana",
    "Santa Rita",
    "Santo Tomas",
    "Sasmuan",
  ],
  Pangasinan: [
    "Alaminos",
    "Dagupan",
    "San Carlos",
    "Urdaneta",
    "Agno",
    "Aguilar",
    "Alcala",
    "Anda",
    "Asingan",
    "Balungao",
    "Bani",
    "Basista",
    "Bautista",
    "Bayambang",
    "Binalonan",
    "Binmaley",
    "Bolinao",
    "Bugallon",
    "Burgos",
    "Calasiao",
    "Dasol",
    "Infanta",
    "Labrador",
    "Laoac",
    "Lingayen",
    "Mabini",
    "Malasiqui",
    "Manaoag",
    "Mangaldan",
    "Mangatarem",
    "Mapandan",
    "Natividad",
    "Pozorrubio",
    "Rosales",
    "San Fabian",
    "San Jacinto",
    "San Manuel",
    "San Nicolas",
    "San Quintin",
    "Santa Barbara",
    "Santa Maria",
    "Santo Tomas",
    "Sison",
    "Sual",
    "Tayug",
    "Umingan",
    "Urbiztondo",
    "Villasis",
  ],
  Quezon: [
    "Lucena",
    "Tayabas",
    "Agdangan",
    "Alabat",
    "Atimonan",
    "Buenavista",
    "Burdeos",
    "Calauag",
    "Candelaria",
    "Catanauan",
    "Dolores",
    "General Luna",
    "General Nakar",
    "Guinayangan",
    "Gumaca",
    "Infanta",
    "Jomalig",
    "Lopez",
    "Lucban",
    "Macalelon",
    "Mauban",
    "Mulanay",
    "Padre Burgos",
    "Pagbilao",
    "Panukulan",
    "Patnanungan",
    "Perez",
    "Pitogo",
    "Plaridel",
    "Polillo",
    "Quezon",
    "Real",
    "Sampaloc",
    "San Andres",
    "San Antonio",
    "San Francisco",
    "San Narciso",
    "Sariaya",
    "Tagkawayan",
    "Tiaong",
    "Unisan",
  ],
  Quirino: [
    "Cabarroguis",
    "Aglipay",
    "Diffun",
    "Maddela",
    "Nagtipunan",
    "Saguday",
  ],
  Rizal: [
    "Antipolo",
    "Angono",
    "Baras",
    "Binangonan",
    "Cainta",
    "Cardona",
    "Jalajala",
    "Morong",
    "Pililla",
    "Rodriguez",
    "San Mateo",
    "Tanay",
    "Taytay",
    "Teresa",
  ],
  Romblon: [
    "Alcantara",
    "Banton",
    "Cajidiocan",
    "Calatrava",
    "Concepcion",
    "Corcuera",
    "Ferrol",
    "Looc",
    "Magdiwang",
    "Odiongan",
    "Romblon",
    "San Agustin",
    "San Andres",
    "San Fernando",
    "San Jose",
    "Santa Fe",
    "Santa Maria",
  ],
  Samar: [
    "Calbayog",
    "Catbalogan",
    "Almagro",
    "Basey",
    "Calbiga",
    "Daram",
    "Gandara",
    "Hinabangan",
    "Jiabong",
    "Marabut",
    "Matuguinao",
    "Motiong",
    "Pagsanghan",
    "Paranas",
    "Pinabacdao",
    "San Jorge",
    "San Jose de Buan",
    "San Sebastian",
    "Santa Margarita",
    "Santa Rita",
    "Santo Niño",
    "Tagapul-an",
    "Talalora",
    "Tarangnan",
    "Villareal",
    "Zumarraga",
  ],
  Sarangani: [
    "Alabel",
    "Glan",
    "Kiamba",
    "Maasim",
    "Maitum",
    "Malapatan",
    "Malungon",
  ],
  Siquijor: [
    "Enrique Villanueva",
    "Larena",
    "Lazi",
    "Maria",
    "San Juan",
    "Siquijor",
  ],
  Sorsogon: [
    "Sorsogon",
    "Barcelona",
    "Bulan",
    "Bulusan",
    "Casiguran",
    "Castilla",
    "Donsol",
    "Gubat",
    "Irosin",
    "Juban",
    "Magallanes",
    "Matnog",
    "Pilar",
    "Prieto Diaz",
    "Santa Magdalena",
  ],
  "South Cotabato": [
    "Koronadal",
    "General Santos",
    "Banga",
    "Lake Sebu",
    "Norala",
    "Polomolok",
    "Santo Niño",
    "Surallah",
    "T'boli",
    "Tampakan",
    "Tantangan",
    "Tupi",
  ],
  "Southern Leyte": [
    "Maasin",
    "Anahawan",
    "Bontoc",
    "Hinunangan",
    "Hinundayan",
    "Libagon",
    "Liloan",
    "Limasawa",
    "Macrohon",
    "Malitbog",
    "Padre Burgos",
    "Pintuyan",
    "Saint Bernard",
    "San Francisco",
    "San Juan",
    "Silago",
    "Sogod",
    "Tomas Oppus",
  ],
  "Sultan Kudarat": [
    "Isulan",
    "Tacurong",
    "Bagumbayan",
    "Columbio",
    "Esperanza",
    "Kalamansig",
    "Lambayong",
    "Lebak",
    "Lutayan",
    "Palimbang",
    "President Quirino",
    "Senator Ninoy Aquino",
  ],
  Sulu: [
    "Jolo",
    "Hadji Panglima Tahil",
    "Indanan",
    "Kalingalan Caluang",
    "Lugus",
    "Luuk",
    "Maimbung",
    "Old Panamao",
    "Omar",
    "Pandami",
    "Panglima Estino",
    "Pangutaran",
    "Parang",
    "Pata",
    "Patikul",
    "Siasi",
    "Talipao",
    "Tapul",
    "Tongkil",
  ],
  "Surigao del Norte": [
    "Surigao",
    "Alegria",
    "Bacuag",
    "Burgos",
    "Claver",
    "Dapa",
    "Del Carmen",
    "General Luna",
    "Gigaquit",
    "Mainit",
    "Malimono",
    "Pilar",
    "Placer",
    "San Benito",
    "San Francisco",
    "San Isidro",
    "Santa Monica",
    "Sison",
    "Socorro",
    "Tagana-an",
    "Tubod",
  ],
  "Surigao del Sur": [
    "Bislig",
    "Tandag",
    "Barobo",
    "Bayabas",
    "Cagwait",
    "Cantilan",
    "Carmen",
    "Carrascal",
    "Cortes",
    "Hinatuan",
    "Lanuza",
    "Lianga",
    "Lingig",
    "Madrid",
    "Marihatag",
    "San Agustin",
    "San Miguel",
    "Tagbina",
    "Tago",
  ],
  Tarlac: [
    "Tarlac",
    "Anao",
    "Bamban",
    "Camiling",
    "Capas",
    "Concepcion",
    "Gerona",
    "La Paz",
    "Mayantoc",
    "Moncada",
    "Paniqui",
    "Pura",
    "Ramos",
    "San Clemente",
    "San Jose",
    "San Manuel",
    "Santa Ignacia",
    "Victoria",
  ],
  "Tawi-Tawi": [
    "Bongao",
    "Languyan",
    "Mapun",
    "Panglima Sugala",
    "Sapa-Sapa",
    "Sibutu",
    "Simunul",
    "Sitangkai",
    "South Ubian",
    "Tandubas",
    "Turtle Islands",
  ],
  Zambales: [
    "Olongapo",
    "Balanga",
    "Botolan",
    "Cabangan",
    "Candelaria",
    "Castillejos",
    "Iba",
    "Masinloc",
    "Palauig",
    "San Antonio",
    "San Felipe",
    "San Marcelino",
    "San Narciso",
    "Santa Cruz",
    "Subic",
  ],
  "Zamboanga del Norte": [
    "Dipolog",
    "Dapitan",
    "Baliguian",
    "Godod",
    "Gutalac",
    "Jose Dalman",
    "Kalawit",
    "Katipunan",
    "La Libertad",
    "Labason",
    "Leon B. Postigo",
    "Liloy",
    "Manukan",
    "Mutia",
    "Piñan",
    "Polanco",
    "Pres. Manuel A. Roxas",
    "Rizal",
    "Salug",
    "Sergio Osmeña Sr.",
    "Siayan",
    "Sibuco",
    "Sibutad",
    "Sindangan",
    "Siocon",
    "Sirawai",
    "Tampilisan",
  ],
  "Zamboanga del Sur": [
    "Pagadian",
    "Zamboanga City",
    "Aurora",
    "Bayog",
    "Dimataling",
    "Dinas",
    "Dumalinao",
    "Dumingag",
    "Guipos",
    "Josefina",
    "Kumalarang",
    "Labangan",
    "Lakewood",
    "Lapuyan",
    "Mahayag",
    "Margosatubig",
    "Midsalip",
    "Molave",
    "Pitogo",
    "Ramon Magsaysay",
    "San Miguel",
    "San Pablo",
    "Sominot",
    "Tabina",
    "Tambulig",
    "Tigbao",
    "Tukuran",
    "Vincenzo A. Sagun",
  ],
  "Zamboanga Sibugay": [
    "Ipil",
    "Alicia",
    "Buug",
    "Diplahan",
    "Imelda",
    "Kabasalan",
    "Mabuhay",
    "Malangas",
    "Naga",
    "Olutanga",
    "Payao",
    "Roseller Lim",
    "Siay",
    "Talusan",
    "Titay",
    "Tungawan",
  ],
};

const Forms = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    bday: "",
    email: "",
    phone: "",
    street: "",
    city: "",
    province: "",
    postalCode: "",
    availability: "",
    resume: "",
    video: "",
    moreInformation: "",
    acknowledgedDisclaimer: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email.endsWith("@gmail.com")) {
      toast.error("Email must be formatted as @gmail.com");
      return;
    }

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      const response = await axios.post(
        "http://localhost/api/submitApplication.php",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        toast.success("Application submitted successfully!");
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
    }
  };

  return (
    <section id="applyforms" className="py-5">
      <ToastContainer />
      <div className="container" style={{ fontFamily: "Inter, sans-serif" }}>
        <div className="row">
          <div className="col-12 text-center">
            <div className="mb-4" style={{ fontWeight: 800, fontSize: "45px" }}>
              LET’S START YOUR JOURNEY
            </div>
            <div className="mb-5">
              Straightforward journey that starts with your desire to learn and
              transform your career.
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 offset-md-2">
            <div className="p-4 bg-white shadow-sm">
              <div className="mb-5 text-center">
                <p
                  style={{
                    fontWeight: 800,
                    fontSize: "30px",
                  }}
                >
                  Application Form
                </p>
                <div className="small text-muted">
                  Already applied? Check the status of your application by
                  sending email on us
                </div>
              </div>

              <h5 className="mb-4 fw-bold border-bottom">
                Personal Information
              </h5>
              <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      placeholder="First Name"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      name="middleName"
                      placeholder="Middle Name"
                      value={formData.middleName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      placeholder="Last Name"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-0">
                  <div className="col-md-4">
                    <input
                      type="date"
                      className="form-control  mb-3"
                      name="bday"
                      value={formData.bday}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <div className="input-group">
                      <span className="input-group-text">+63</span>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        placeholder="9123456789"
                        value={formData.phone}
                        onChange={handleChange}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        maxLength="10"
                      />
                    </div>
                  </div>
                </div>

                <h5 className="mb-4 fw-bold border-bottom">Home Address</h5>
                <div className="row mb-3">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      name="street"
                      placeholder="Street"
                      value={formData.street}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-4">
                    <select
                      className="form-select  mb-3"
                      name="province"
                      value={formData.province}
                      onChange={handleChange}
                    >
                      <option value="">Select Province</option>
                      {provinces.map((province) => (
                        <option key={province} value={province}>
                          {province}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <select
                      className="form-select  mb-3"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                      disabled={!formData.province}
                    >
                      <option value="">Select City</option>
                      {formData.province &&
                        cities[formData.province].map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      name="postalCode"
                      placeholder="Postal Code"
                      value={formData.postalCode}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <h5 className="mb-4 fw-bold border-bottom">
                  Employment Information
                </h5>
                <div className="col-md-5">
                  <select
                    className="form-select"
                    name="availability"
                    placeholder="Availability"
                    defaultValue={""}
                    value={formData.availability}
                    onChange={handleChange}
                  >
                    <option value="">Availability</option>
                    <option value="full-time">Full Time</option>
                    <option value="part-time">Part Time</option>
                    <option value="contract">Contract</option>
                  </select>
                </div>
                <br />
                <h5 className="fw-bold border-bottom">Resume</h5>
                <div className="mb-3">
                  <input
                    type="file"
                    className="form-control"
                    id="resume"
                    name="resume"
                    onChange={handleChange}
                  />
                  <div
                    className="text-center text-secondary"
                    style={{ fontSize: "12px" }}
                  >
                    Accepted File Extensions: pdf. Maximum size of 2Mb. If you
                    need help converting a file to PDF.
                  </div>
                </div>

                <h5 className="fw-bold border-bottom">Upload Video</h5>
                <div className="text-secondary" style={{ fontSize: "12px" }}>
                  Upload a 1 minute video introducing yourself and highlighting
                  your skills and experiences. Maximum size of 100 Mb{" "}
                  <span className="fw-bold">Please wear business attire</span>
                </div>

                <div className="mb-3">
                  <input
                    type="file"
                    className="form-control"
                    id="video"
                    name="video"
                    accept="video/*"
                    onChange={handleChange}
                  />
                </div>

                <h5 className="fw-bold border-bottom">More Information</h5>
                <div className="text-secondary" style={{ fontSize: "12px" }}>
                  Tell us about yourself, skills and experience. Explain why
                  should you be considered over any other applicant?
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    name="moreInformation"
                    placeholder="Write anything here..."
                    rows="5"
                    value={formData.moreInformation}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div className="mt-5 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    name="acknowledgedDisclaimer"
                    checked={formData.acknowledgedDisclaimer}
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="exampleCheck1"
                    style={{ fontSize: "13px" }}
                  >
                    I hereby acknowledge that I have read and understood the
                    items listed in CyberPros Applicant Disclaimer. I will make
                    all the proper arrangements to meet the requirements and
                    proceed with my application to CyberPros.ph
                  </label>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <button type="submit" className="btn btn-primary">
                    SUBMIT APPLICATION
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Forms;
