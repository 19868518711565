import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../assets/cplogo.png";
import IndustryNav from "./IndustryNav";
import "./Navbar.css";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [iframeModalOpen, setIframeModalOpen] = useState(false);
  const [initialIframeLoaded, setInitialIframeLoaded] = useState(false);
  const location = useLocation();
  const iframeRef = useRef(null);

  const navItems = [
    { label: "About", href: "/about" },
    { label: "Pricing", href: "/pricing" },
    { label: "FAQs", href: "/FAQs" },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setMenuOpen(false); 
    setModalOpen(false); 
    setIframeModalOpen(false);
    setInitialIframeLoaded(false);
  }, [location]);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setMenuOpen(false);
    setModalOpen(false);
    setIframeModalOpen(false);
  };

  const handleIframeLoad = () => {
    if (!initialIframeLoaded) {
      setInitialIframeLoaded(true);
    } else {
      // Set a 5-second delay before closing the modal
      setTimeout(() => {
        setIframeModalOpen(false);
      }, 5000);
    }
  };

  return (
    <nav className="bg-light text-dark text-center py-3 sticky-top">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center flex-row">
          <NavLink to={"/home"} onClick={handleClick} className="logo-container">
            <img src={Logo} alt="Cyber Pros Logo" className="navbar-logo" />
          </NavLink>
          <div className="d-flex justify-content-between align-items-center">
            <button className="navbar-toggler" onClick={() => setMenuOpen(!menuOpen)}>
              {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
            <nav className={`nav-menu ${menuOpen ? "open" : ""}`}>
              <a href="/home" className="text-dark mx-3 nav-items text-decoration-none" onClick={handleClick}>Home</a>
              <a href="/services" className="text-dark mx-3 nav-items text-decoration-none" onClick={handleClick}>Services</a>
              <button className="text-dark mx-3 nav-items text-decoration-none" style={{ background: "none", border: "none" }} onClick={() => setModalOpen(true)}>
                Industries
              </button>
              {navItems.map((item, index) => (
                <a href={item.href} key={index} className="text-dark mx-3 nav-items text-decoration-none" onClick={handleClick}>{item.label}</a>
              ))}
              <button className="btn ms-3" style={{ borderRadius: "10px" }} onClick={() => setIframeModalOpen(true)}>Book a Strategy Call</button>
            </nav>
          </div>
        </div>
      </div>

      {/* Industry Modal */}
      {modalOpen && (
        <div className="modal-overlay" onClick={() => setModalOpen(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-modal" onClick={() => setModalOpen(false)}>
              <FaTimes size={24} />
            </button>
            <IndustryNav />
          </div>
        </div>
      )}

      {/* Google Form Modal */}
      {iframeModalOpen && (
        <div className="modal-overlay" style={{zIndex:'1200'}} onClick={() => setIframeModalOpen(false)}>
          <div className="modal-content iframe-modal" onClick={(e) => e.stopPropagation()}>
            <button className="close-modal" onClick={() => setIframeModalOpen(false)}>
              <FaTimes size={24} />
            </button>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSf2LSq9FBc9d-olv7iu-Y2tUBogYAmgnmn3Z2NXATJ5lBN1Lg/viewform"
              width="100%"
              height="500px"
              frameBorder="0"
              title="Strategy Call Form"
              allowFullScreen
              ref={iframeRef}
              onLoad={handleIframeLoad} // Check on load
            ></iframe>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
