import React, { useEffect } from "react";
import SectionImg from "../../assets/section.png";
import "./Grow.css";

const Grow = () => {
  const sectionData = [
    {
      image: SectionImg,
      alt: "Grow Your Business",
      title: "Cyber Pros Mission and Vision",
      text: [
        "Our mission at CYBER PROS is to provide reliable, personalized virtual professional services that enable our clients to focus on their core objectives and achieve greater productivity. We are committed to delivering superior support through skilled professionals, advanced technology, and a commitment to exceeding client expectations. By fostering a culture of efficiency, integrity, and continuous improvement, we aim to be the trusted partner in an increasingly digital world.",
        "At CYBER PROS, our vision is to empower individuals and businesses worldwide through seamless and innovative virtual assistant solutions. We strive to redefine productivity by delivering exceptional virtual support services that anticipate and exceed the evolving needs of our clients. By leveraging cutting-edge technology and a dedicated team of professionals, we aim to foster efficiency, growth, and success in every partnership we cultivate.",
      ],
    },
  ];

  useEffect(() => {
    const updateRibbonMargin = () => {
      const image = document.querySelector(".img-fluid");
      const ribbon = document.querySelector(".ribbon");

      if (window.innerWidth < 760 && image && ribbon) {
        const imageHeight = image.offsetHeight;
        ribbon.style.marginTop = `${imageHeight + 120}px`;
      } else {
        ribbon.style.marginTop = `120px`;
      }
    };

    updateRibbonMargin(); // Initial call
    window.addEventListener("resize", updateRibbonMargin); // Update on resize

    return () => {
      window.removeEventListener("resize", updateRibbonMargin);
    };
  }, []);

  return (
    <section id="grow" className="py-5">
      <div className="ribbon" /> {/* Full-width background */}
      <div className="container">
        <div className="row align-items-center">
          {sectionData.map((item, index) => (
            <React.Fragment key={index}>
              <div className="col-md-6">
                <img src={item.image} alt={item.alt} className="img-fluid" />
              </div>
              <div className="col-md-6 text-center text-md-left">
                <div className="text-light m-auto py-2 px-3 mb-5 mt-5">
                  <h2 className="big-text">{item.title}</h2>
                </div>
                <div className="px-3">
                  {item.text.map((paragraph, paragraphIndex) => (
                    <p key={paragraphIndex} className="lil-text">
                      {paragraph}
                    </p>
                  ))}
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Grow;
