import React, { useState } from "react";
import "./Hero3.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Hero3 = () => {
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		contactNumber: "",
		companyName: "",
		video: null,
		indSerCategory: "",
		selectedOption: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleIndSerCategoryChange = (e) => {
		const value = e.target.value;
		setFormData((prevData) => ({
			...prevData,
			indSerCategory: value,
			selectedOption: "", // Reset selected option when category changes
		}));

		if (value === "services") {
			setOptions(servicesOptions);
		} else if (value === "industry") {
			setOptions(industryOptions);
		}
	};

	const handleOptionChange = (e) => {
		const value = e.target.value;
		setFormData((prevData) => ({
			...prevData,
			selectedOption: value,
		}));
	};

	const [options, setOptions] = useState([]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const form = new FormData();
		for (const key in formData) {
			if (formData.hasOwnProperty(key)) {
				form.append(key, formData[key]);
			}
		}

		try {
			const response = await axios.post(
				"https://cyberpros.ph/api/bookStrategy.php",
				form,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);

			console.log("Response data:", response.data); // Debugging

			if (response.data.status === "success") {
				toast.success(response.data.message);
				// Reset form data
				setFormData({
					firstName: "",
					lastName: "",
					email: "",
					contactNumber: "",
					companyName: "",
					video: null,
					indSerCategory: "",
					selectedOption: "",
				});
			} else {
				toast.error(response.data.message);
			}
		} catch (error) {
			toast.error("There was an error submitting the form!");
			console.error("There was an error submitting the form!", error);
		}
	};

	const servicesOptions = [
		"Administrative",
		"Customer service",
		"Marketing",
		"Inside sales agent",
		"Sales development",
		"Transaction coordinator",
		"Executive virtual assistant",
		"Information technology",
		"Engineering support",
	];

	const industryOptions = [
		"Real Estate",
		"Information Technology",
		"Insurance",
		"Human resource",
		"Marketing",
		"Legal",
		"Logistics",
		"Accounting and bookkeeping",
		"Healthcare",
		"Engineering/Construction",
	];

	return (
		<section id="hero3">
	<ToastContainer />
	<div className="bg-blur"></div>
	<div className="container d-flex align-items-center" style={{ minHeight: '100vh' }}>
	<div className="row w-100 mx-0">
		<div className="col-lg-6 d-none d-lg-block position-relative d-flex justify-content-center align-items-center">
			<div className="circle1"></div>
			<div className="circle2"></div>
			<div className="triangle1"></div>
			<div className="triangle2"></div>
			<div className="circle3"></div>
		</div>
		<div className="col-lg-6  col-12 align-items-center mb-5">
			<div className="me-5">
				<h1 className="text-light fw-bolder mb-5">
					Strategy Call with an <span>Expert</span>
				</h1>
				<form onSubmit={handleSubmit} className="heroForm">
					<div className="row mb-2">
						<div className="col-md-6 mb-2">
							<label htmlFor="firstName" className="form-label">
								First Name <span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className="form-control"
								name="firstName"
								value={formData.firstName}
								onChange={handleChange}
							/>
						</div>
						<div className="col-md-6 mb-2">
							<label htmlFor="lastName" className="form-label">
								Last Name <span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className="form-control"
								name="lastName"
								value={formData.lastName}
								onChange={handleChange}
							/>
						</div>
					</div>
					<div className="mb-2">
						<label htmlFor="email" className="form-label">
							Business Email Address <span className="text-danger">*</span>
						</label>
						<input
							type="email"
							className="form-control"
							name="email"
							value={formData.email}
							onChange={handleChange}
						/>
					</div>
					<div className="mb-2">
						<label htmlFor="contactNumber" className="form-label">
							Contact Number <span className="text-danger">*</span>
						</label>
						<input
							type="text"
							className="form-control"
							name="contactNumber"
							value={formData.contactNumber}
							onChange={handleChange}
						/>
					</div>
					<div className="row mb-2">
						<div className="col-md-12 mb-2">
							<label htmlFor="indSerCategory" className="form-label">
								Industry/Services <span className="text-danger">*</span>
							</label>
							
						</div>
						<div className="col-md-12 d-flex mb-2 ">
						<select
								name="indSerCategory"
								value={formData.indSerCategory}
								onChange={handleIndSerCategoryChange}
								className="form-select mr-3"
							>
								<option value="">Select Category</option>
								<option value="industry">Industry</option>
								<option value="services">Services</option>
							</select>
							<select
								name="selectedOption"
								value={formData.selectedOption}
								onChange={handleOptionChange}
								className="form-select"
							>
								<option value="">Select Option</option>
								{options.map((option, index) => (
									<option key={index} value={option}>
										{option}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="mb-3">
						<label htmlFor="companyName" className="form-label">
							Company Name <span className="text-danger">*</span>
						</label>
						<input
							type="text"
							className="form-control"
							name="companyName"
							value={formData.companyName}
							onChange={handleChange}
						/>
					</div>

					<button
						type="submit"
						className="btn rounded-pill px-3"
					>
						Book a strategy call
					</button>
				</form>
			</div>
		</div>
	</div>
</div>

</section>

	);
};

export default Hero3;
