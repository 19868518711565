import React from "react";
import grow1 from "../../assets/Services/Grow1.jpg";
import "./Grow1.css";

const Grow1 = () => {
  return (
    <section id="grow1" className="py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-9 col-md-10 mx-auto">
            <div className="row align-items-center">
              <div className="col-md-6">
                <img
                  src={grow1}
                  alt="Grow your Business"
                  className="img-fluid growPic"
                />
              </div>
              <div className="col-md-6">
                <div className="text-light mb-3">
                  <h2 className="big-text">
                    Focusing On <span className="text-danger">Growth</span>
                  </h2>
                </div>
                <div className="text-light">
                  <p className="lil-text">
                    <span>Cyber Pros</span> is a company that provides business
                    owners the adaptability, scalability, and cost savings they
                    need to adjust swiftly to changing business and market
                    situations without incurring the cost or commitment of
                    hiring local full-time staff.
                  </p>
                  <p className="lil-text">
                    Our virtual professionals are equipped to do everything from
                    office administration to inside sales, support, marketing,
                    and more. "We will” help you reduce time spent on repetitive
                    tedious work and activities, allowing you to focus on
                    expanding your business while saving you up to 60% on the
                    expense of hiring a local full time employee
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Grow1;
