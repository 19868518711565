import React from "react";
import { NavLink } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import "./Plan.css";
import Ate from "../../../assets/Pricing/picture/pricing3.png";

const Plan = () => {
  return (
    <section id="plan" className="mr-5 ml-5 py-5">
      <div className="gradBG d-flex flex-column flex-md-row justify-content-center p-4 p-md-5">
        <img src={Ate} alt="SiAte" className="img-fluid mb-4 mb-md-0" />
        <div className="planx p-3 p-md-5">
          <div className="insidePlan p-3 p-md-5">
            <div className="bonus shadow p-3">
              <p>
                Price starts at $1,888 <span> /month</span>
              </p>
            </div>
            <h3 className="mt-4 pt-3 fw-bold">
              Unmatched ROI with Our Virtual Professionals Services
            </h3>
            <p className="mt-4 fs-6 fs-md-5">
              When compared to full-time employees, virtual professionals can
              save you up to 60% on payroll, all without the hassles of
              unemployment, insurance, retirement, or office and equipment fees.
            </p>
            <p>
              <span>
                <FaCheckCircle />
              </span>{" "}
              Personality Profiling
            </p>
            <p>
              <span>
                <FaCheckCircle />
              </span>{" "}
              Standardized Office and Equipment Policies
            </p>
            <p>
              <span>
                <FaCheckCircle />
              </span>{" "}
              Productivity Tracking Software (MyTimeIn)
            </p>
            <p>
              <span>
                <FaCheckCircle />
              </span>{" "}
              College-Educated with Professional Experience
            </p>
            <p>
              <span>
                <FaCheckCircle />
              </span>{" "}
              Talent Onboarding & Training Programs
            </p>
            <div className="text-end mt-4">
              <NavLink to="/book" className="btn btn-lg btn-primary">
                Choose Plan
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Plan;
