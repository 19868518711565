import React from "react";
import AAB from "../../../assets/Indus/icons/AAB.png";
import HC from "../../../assets/Indus/icons/HC.png";
import IT from "../../../assets/Indus/icons/IT.png";
import LEGAL from "../../../assets/Indus/icons/LEGAL.png";
import EC from "../../../assets/Indus/icons/EC.png";
import HR from "../../../assets/Indus/icons/HC.png";
import INSUR from "../../../assets/Indus/icons/INSUR.png";
import LOGIS from "../../../assets/Indus/icons/LOGIS.png";
import MARKET from "../../../assets/Indus/icons/MARKET.png";
import RE from "../../../assets/Indus/icons/RE.png";

const WeHelp = () => {
  const cards = [
    {
      icon: AAB,
      label: "Accounting and Bookkeeping",
    },
    {
      icon: HC,
      label: "HealthCare",
    },
    {
      icon: IT,
      label: "Information and Technology",
    },
    {
      icon: LEGAL,
      label: "Legal",
    },
    {
      icon: EC,
      label: "Engineering and Construction",
    },
    {
      icon: HR,
      label: "Human Resources",
    },
    {
      icon: INSUR,
      label: "Insurance",
    },
    {
      icon: LOGIS,
      label: "Logistics",
    },
    {
      icon: "",
      label: "",
    },
    {
      icon: MARKET,
      label: "Marketing",
    },
    {
      icon: RE,
      label: "Real Estate",
    },
  ];
  return (
    <div className="weHelp py-5 container">
      <div className="text-center">
        <h1 className="fw-bold mb-5">
          Who We've <span className="text-primary">Helped</span>?
        </h1>

        <div className="row">
          {cards.map((card, index) => (
            <div className="col-3 mb-2" key={index}>
              <div
                className={`rounded-2 p-3 ${
                  index !== 8 ? "border border-3 border-dark" : ""
                }`}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={card.icon}
                    alt={card.label}
                    style={{ height: "3.5rem" }}
                  />
                  <h5
                    style={{ color: "#0A3F60" }}
                    className="ms-3 text-start fw-bold"
                  >
                    {card.label}
                  </h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WeHelp;
