import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import "./Hero.css";

const Hero = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [iframeModalOpen, setIframeModalOpen] = useState(false);
  const [initialIframeLoaded, setInitialIframeLoaded] = useState(false);
  const iframeRef = useRef(null);

  const handleIframeLoad = () => {
    if (!initialIframeLoaded) {
      setInitialIframeLoaded(true);
    } else {
      // Set a 5-second delay before closing the modal
      setTimeout(() => {
        setIframeModalOpen(false);
      }, 5000);
    }
  };

  return (
    <section id="hero" className="d-flex align-items-center py-5 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 text-md-start text-center">
            <h1 className="display-4">Grow your Business with Cyber Pros</h1>
            <h2 className="my-3">VIRTUAL PROFESSIONAL</h2>
            <p className="lead">
              Our dedication to excellence guarantees that you receive top quality service, enabling you to concentrate on your core business objectives while we manage the operational complexities.
            </p>
            <button className="btn btn-primary rounded-pill px-5" onClick={() => setIframeModalOpen(true)}>
              Book a Strategy Call
            </button>
          
          </div>
        </div>
      </div>

    

      {/* Google Form Modal */}
      {iframeModalOpen && (
        <div className="modal-overlay"  style={{zIndex:'1200'}} onClick={() => setIframeModalOpen(false)}>
          <div className="modal-content iframe-modal"  onClick={(e) => e.stopPropagation()}>
            <button className="close-modal" onClick={() => setIframeModalOpen(false)}>
              <FaTimes size={24} />
            </button>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSf2LSq9FBc9d-olv7iu-Y2tUBogYAmgnmn3Z2NXATJ5lBN1Lg/viewform"
              width="100%"
              height="500px"
              frameBorder="0"
              title="Strategy Call Form"
              allowFullScreen
              ref={iframeRef}
              onLoad={handleIframeLoad} // Check on load
            ></iframe>
          </div>
        </div>
      )}
    </section>
  );
};

export default Hero;
