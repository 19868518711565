import React from "react";
import "./BePart.css";

const BePart = () => {
  return (
    <div id="bepartClient">
      <div className="">
        <h1
          className="fst-italic fw-bolder mb-5 text-light"
          style={{ width: "40%" }}
        >
          Partner with Cyber Pros and experience the difference that expert
          virtual professionals can make.
        </h1>
        <a href="/book" className="btn rounded-pill px-5 fw-semibold">
          Book a Strategy Call
        </a>
      </div>
    </div>
  );
};

export default BePart;
