import React from "react";
import "./Success.css";

const Success = ({ successRef }) => {
  return (
    <section
      id="success"
      ref={successRef}
      className="py-5"
      style={{ fontFamily: "Inter, sans-serif" }}
    >
      <div className="container">
        <h1 className="text-center" style={{ fontWeight: 700 }}>
          WHAT WE <span style={{ color: "#31b6dd" }}>DO</span>
        </h1>

        <div />
        <div className="text-center ">
          At Cyber Pros, we provide top-notch Virtual Professionals, including
          customer support, I.T. support, inside sales agent, telemarketing,
          administrative support, engineering support, or whatever you need on
          the back-end administrative tasks which will help streamline your
          operations and reduce costs, letting you focus on your what you do
          best - grow your business!
          <br />
          <br />
          Choose Cyber Pros for cost-effective, scalable solutions and a
          customer-centric approach. Our service does not stop at providing
          virtual professionals that will suit you and your company but also
          continuous support to make sure your VP can keep up with the progress
          your company is making. With advanced technology and industry
          expertise, we tailor our services to meet your needs and drive your
          success. Contact us today to learn how we can support your business
          growth.
        </div>
      </div>
    </section>
  );
};

export default Success;
